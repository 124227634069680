
import axios from 'axios';
import './App.css';
import { Card, Label, Select, Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}


function App() {

  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [communeData, setCommuneData] = useState([]);

  useEffect(() => {
    fetchData();
    setCurrentTime();

  }, []);

  function getDayName(dateStr, locale)
{
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });        
}

  const setCurrentTime = () => {
    const time = new Date()
    localStorage.setItem("currentTime", time.getHours())
  }
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://midas.minsal.cl/farmacia_v2/WS/getLocalesTurnos.php');
      setData(response.data);
      setLoading(false);
    } catch  
(error) {
      setError(error);  

    }
  };

  const communes = data.map((item) => item.comuna_nombre);
  const uniqueCommunes = communes.filter(onlyUnique).sort((a, b) => a.localeCompare(b))

  const handleCommuneSelect = (event) => {
    const selectedCommune = event.target.value;
    const filteredData = data.filter((item) => item.comuna_nombre === selectedCommune);
    setCommuneData(filteredData);
    let currentHour = new Date().getHours()
    if (localStorage.getItem("currentTime") != currentHour) {
      fetchData()
      setCurrentTime()
    }
  }
  const currentTime = new Date().toLocaleTimeString();

  var dateStr = new Date();
  var day = getDayName(dateStr, "es-CL");
  if (loading) return <div className="max-w-7xl mx-auto px-4 py-2"><span>Cargando...</span><br/><Spinner aria-label="Default status example" /></div>
  return (
    <div className="max-w-7xl mx-auto px-4 py-2">
      <div>
        Farmacias Abiertas <text className="font-bold" style={{textTransform: 'capitalize' }}>{day} {currentTime}</text>
      </div>
      <div>
        <div className="max-w-md">
          <div className="mb-2 block">
            <Label htmlFor="communes" value="Comuna" />
          </div>
          <Select id="communes" required onChange={handleCommuneSelect}>
            {uniqueCommunes.map((commune, idx) => <option key={idx}>{commune}</option>)}
          </Select>
        </div>
      </div>
      <div className="mt-2">
      {communeData && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 space-y-4">
          {communeData.map((item, idx) => (
            <Card key={idx} className="max-w-sm hover:bg-gray-100">
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {item.local_nombre}
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                Dirección: {item.local_direccion}
              </p>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                Horario: {item.funcionamiento_hora_apertura} a {item.funcionamiento_hora_cierre}
              </p>
            </Card>
          ))}
        </div>
      )}
      </div>
    </div>
  );
}

export default App;
